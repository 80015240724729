<template>
  <el-container style="height: calc( 100% - 42px)">
    <div :style="{backgroundColor: configset.framecolor,position: 'absolute',height:'40px',width: '100%',color: 'white','font-size':'16px','line-height': '40px','padding-left': '10px'} ">
      <div style="display: flex">
        <div style="margin-right: 3px;">
          <img :src="configset.linkLogo" style="width: 20px;height: 20px;vertical-align: middle;">
        </div>
        <div>{{configset.linkName}}</div>
      </div>
    </div>
    <TalkView @configset="getConfigset" :tokenkey="tokenkey" :customerId="customerId" style="margin-top: 42px;"></TalkView>
  </el-container>
</template>
<script>
  import TalkView from './components/TalkView'
  export default {
    name: "index",
    metaInfo: {
      title: '杭州思软客服系统',
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no' }
      ]
    },
    components: {
      TalkView,
    },
    data() {
      return {
        tokenkey: this.$route.query.tokenkey,
        customerId: this.$route.query.s,
        configset: {
          framecolor: '',
          linkLogo: '',
          linkName: '',
        }
      }
    }
    , created() {
      window.top.document.title = '';
    }
    , methods: {
      getConfigset(val) {
        this.configset = val;
      }
    }
  }
</script>

<style>
  .bottom-title {
    bottom: 0px !important;
  }
  .bg-white {
    margin-bottom: 8px;
  }
</style>
